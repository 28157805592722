
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class IrrigationDetail extends Vue {
  private activeName = 'setting'
  projectId = ''
  created () {
    this.activeName = this.$route.path.substr(this.$route.path.lastIndexOf('/') + 1)
    this.projectId = this.$route.params.projectId as string
  }

  handleClick () {
    const path = `/irrigationmodelsetting/detail/${this.activeName}`
    if (path !== this.$route.path) {
      this.$router.push({
        path: path,
        query: {
          projectId: this.$route.query.projectId
        }
      })
    }
  }
}
